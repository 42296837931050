<script>
// import axios from 'axios';

export default {
  name: 'TokenAuth',
  methods: {
    validateToken() {
      const token = this.$route.params.token;

      this.$store.dispatch('auth/setToken', token);

      this.$store
        .dispatch('auth/validate', true)
        .then(() => {
          this.$router.push(
            this.$route.query.redirect
              ? `/${this.$route.query.redirect}`
              : {
                  name: 'home',
                }
          );
        })
        .catch(() => {
          this.$router.push({ name: 'login', query: { errorCode: 'wrongToken' } });
        });
    },
  },
  mounted() {
    this.validateToken();
  },
};
</script>

<template>
  <b-overlay :show="true" spinner-variant="primary" rounded="sm">
    <div class="token-auth"></div>
  </b-overlay>
</template>

<style lang="scss" scoped>
.token-auth {
  height: 100vh;
}
</style>
